import React from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { sandbox_id } from '../sandbox';
import { sandbox_id2 } from '../sandbox';
import './Paypal.css';
 
export default class Paypal extends React.Component {
    constructor() {
        super()
        this.state = {
            paymentSuccess: false,
            paymentCancelled: false,
            paymentError: false,
            paymentTotal: 0
        }
    }

    handleKeyPress = (event) => {
        const { name, value } = event.target;
        this.setState({[name]: value});
    }

    render() {
        const onSuccess = (payment) => {
            this.setState({paymentSuccess: true})
            console.log("The payment was succeeded!", payment);
        }
 
        const onCancel = (data) => {
            this.setState({paymentCancelled: true})
            console.log('The payment was cancelled!', data);
        }
 
        const onError = (err) => {
            this.setState({paymentError: true})
            console.log("Error!", err);
        }
 
        let env = 'production'; 
        let currency = 'USD'; 
        let total = this.state.paymentTotal; 
 
        const client = {
            sandbox:    `${sandbox_id}`,
            production: `${sandbox_id2}`,
        }

        const { paymentCancelled, paymentSuccess, paymentError, paymentTotal } = this.state;
        return (
            <div className='paypal'>
                <div className='paypal-info'>
                {
                    paymentSuccess && 
                    <article className='successfull-payment'>
                        <h1 className='payment-option'>Congrats, the payment was successfull!</h1>
                    </article>
                }
                {
                    paymentError && 
                    <article className='successfull-payment'>
                        <h1 className='payment-option'>We're sorry, something went wrong!</h1>
                    </article>
                }
                {
                    paymentCancelled && 
                    <article className='successfull-payment'>
                        <h1 className='payment-option'>The payment was cancelled!</h1>
                    </article>
                }   
                    <h1 className='payment-header'>Payment</h1>
                    <h3 className='payment-instructions'>Use to paypal button to securely checkout</h3>
                    <p className='payment-instructions'>Please enter dollar amount below</p>
                    $<input className='total-payment-input' type='integer' name='paymentTotal' placeholder='Price' value={paymentTotal} onChange={this.handleKeyPress} />
                    <PaypalExpressBtn env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
                </div>
            </div>
        );
    }
}