import React, { Component } from 'react';
import './Application.css';

class application extends Component {
    constructor() {
        super()
        this.state = {
            firstName: '',
            middleName: '',
            lastName: '',
            phone: '',
            address: '',
            city: '',
            stateCode: '',
            zip: '',
            birthday: '',
            email: '',
            position: '',
            startDate: '',
            endDate: '',
            schedule: '',
            daysOff: '',
            lifeguardCertification: '',
            CPRCertification: '',
            WSICertification: '',
            highschool: '',
            hsGraduation: '',
            college: '',
            cGraduation: '',
            job1Name: '',
            position1: '',
            position1Start: '',
            position1End: '',
            wage1: '',
            job1leave: '',
            job2Name: '',
            position2: '',
            position2Start: '',
            position2End: '',
            wage2: '',
            job2leave: '',
            job3Name: '',
            position3: '',
            position3Start: '',
            position3End: '',
            wage3: '',
            job3leave: '',
            reference1: '',
            reference1Number: '',
            reference2: '',
            reference2Number: '',
            reference3: '',
            reference3Number: '',
            comments: ''
        }
    }

    handleKeyPress = (event) => {
        const { name, value } = event.target;
        this.setState({[name]: value});
    }

    render() {
        const { firstName, middleName, lastName, phone, address, city, zip, birthday, email, startDate, endDate, daysOff, lifeguardCertification, CPRCertification, WSICertification, highschool, hsGraduation, college, cGraduation, job1Name, position1, position1Start, position1End, wage1, job1leave, job2Name, position2, position2Start, position2End, wage2, job2leave, job3Name, position3, position3Start, position3End, wage3, job3leave, reference1, reference1Number, reference2, reference2Number, reference3, reference3Number, comments } = this.state;

        const isEnabled =
            email.length > 0 &&
            firstName.length > 0 &&
            lastName.length > 0 &&
            phone.length > 0 &&
            address.length > 0 &&
            city.length > 0 &&
            zip.length > 0 &&
            birthday.length > 0 &&
            email.length > 0 &&
            startDate.length > 0 &&
            endDate.length > 0 &&
            highschool.length > 0 &&
            hsGraduation.length > 0 

        return (
            <div className='application'>
                <div>
                    <h1 className='application-header'>Apply Online!</h1>
                    <hr></hr>
                    <h3 className='complete-fields'>Please complete all the following information</h3>
                    <p className='complete-fields'><span className='star'>*</span>Denotes a Required Field</p>
                </div>
                <form action="https://formspree.io/7micdoherty7@gmail.com" method="POST" >
                    <div className='personal-info'>
                        <p><span className='star'>*</span>First Name</p>
                        <input className='name-input' type='text' name='firstName' placeholder='First Name' value={firstName} onChange={this.handleKeyPress} />
                        <p>Middle Name</p>
                        <input className='name-input' type='text' name='middleName' placeholder='Middle Name' value={middleName} onChange={this.handleKeyPress} />
                        <p><span className='star'>*</span>Last Name</p>
                        <input className='name-input' type='text' name='lastName' placeholder='Last Name' value={lastName} onChange={this.handleKeyPress} />
                        <p><span className='star'>*</span>Phone Number</p>
                        <input className='name-input' type='text' name='phone' placeholder='Phone Number' value={phone} onChange={this.handleKeyPress} />
                        <p><span className='star'>*</span>Address</p>
                        <input className='name-input' type='text' name='address' placeholder='Address' value={address} onChange={this.handleKeyPress} />
                        <p><span className='star'>*</span>City</p>
                        <input className='name-input' type='text' name='city' placeholder='City' value={city} onChange={this.handleKeyPress} />
                        <p><span className='star'>*</span>State</p>
                        <select name="stateCode" onChange={this.handleKeyPress}>
                            <option value=''>*Please Select*</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        <p><span className='star'>*</span>Zip Code</p>
                        <input className='name-input' type='text' name='zip' placeholder='Zip Code' value={zip} onChange={this.handleKeyPress} />
                        <p><span className='star'>*</span>Birthday</p>
                        <input type="text" className='name-input' name="birthday" placeholder='mm/dd/yyyy' value={birthday} onChange={this.handleKeyPress}/>
                        <p><span className='star'>*</span>Email</p>
                        <input type="email" className='name-input' name="email" placeholder='Please enter an email you check frequently' value={email} onChange={this.handleKeyPress}/>
                    </div>
                <div className='desired-employment'>
                    <h1 className='application-header'>Employment Desired</h1>
                    <hr></hr>
                </div>
                    <div className='position-field'>
                        <p><span className='star'>*</span>I wish to apply for the following position</p>
                        <select name="position" onChange={this.handleKeyPress}>
                            <option value=''>*Please Select*</option>
                            <option value='Lifeguard'>Lifeguard</option>
                            <option value="Swim Instructor">Swim Instructor</option>
                        </select>
                        <p><span className='star'>*</span>First day you can work</p>
                        <input className='name-input' type="text" name="startDate" placeholder='Pools open May 25th, 2019' value={startDate} onChange={this.handleKeyPress}/>
                        <p><span className='star'>*</span>Last day you can work</p>
                        <input className='name-input' type="text" name="endDate" placeholder='Pools close September 2nd, 2019' value={endDate} onChange={this.handleKeyPress}/>
                        <p><span className='star'>*</span>Full time or part time</p>
                        <select name="schedule" onChange={this.handleKeyPress}>
                            <option value=''>*Please Select*</option>
                            <option value='Full Time'>Full Time</option>
                            <option value="Part Time">Part Time</option>
                        </select>
                    </div>
                <div className='desired-employment'>
                    <h1 className='application-header'>Qualifications</h1>
                    <hr></hr>
                </div>
                    <div className='position-field'>
                        <p>Lifeguard Certification</p>
                        <input className='name-input' type="text" name="lifeguardCertification" placeholder='Date of Issue' value={lifeguardCertification} onChange={this.handleKeyPress}/>
                        <p>CPR Certification</p>
                        <input className='name-input' type="text" name="CPRCertification" placeholder='Date of Issue' value={CPRCertification} onChange={this.handleKeyPress}/>
                        <p>WSI or SwimAmerica Certification</p>
                        <input className='name-input' type="text" name="WSICertification" placeholder='Date of Issue' value={WSICertification} onChange={this.handleKeyPress}/>
                    </div>
                <div className='desired-employment'>
                    <h1 className='application-header'>Education Background</h1>
                    <hr></hr>
                </div>
                    <div className='position-field'>
                        <p><span className='star'>*</span>High School</p>
                        <input className='name-input' type='text' name='highschool' placeholder='High School Name' value={highschool} onChange={this.handleKeyPress} />
                        <p><span className='star'>*</span>Date of High School Graduation</p>
                        <input className='name-input' type='text' name='hsGraduation' placeholder='Date of graduation' value={hsGraduation} onChange={this.handleKeyPress} />
                        <p>College Name</p>
                        <input className='name-input' type='text' name='college' placeholder='College Name' value={college} onChange={this.handleKeyPress} />
                        <p>Date of college graduation</p>
                        <input className='name-input' type='text' name='cGraduation' placeholder='Date of college graduation' value={cGraduation} onChange={this.handleKeyPress} />
                    </div>
                <div className='desired-employment'>
                    <h1 className='application-header'>Employment Background</h1>
                    <hr></hr>
                </div>
                    <div className='position-field'>
                        <h3>Job 1</h3>
                        <p>Place of Employment</p>
                        <input className='name-input' type='text' name='job1Name' placeholder='Place of employment' value={job1Name} onChange={this.handleKeyPress} />
                        <p>Your position</p>
                        <input className='name-input' type='text' name='position1' placeholder='Your position' value={position1} onChange={this.handleKeyPress} />
                        <p>Date Started</p>
                        <input className='name-input' type='text' name='position1Start' placeholder='Date Started' value={position1Start} onChange={this.handleKeyPress} />
                        <p>Date Ended</p>
                        <input className='name-input' type='text' name='position1End' placeholder='Date Ended' value={position1End} onChange={this.handleKeyPress} />
                        <p>Wage</p>
                        <input className='name-input' type='text' name='wage1' placeholder='Wage' value={wage1} onChange={this.handleKeyPress} />
                        <p>Reason for Leaving</p>
                        <input className='name-input' type='text' name='job1Leave' placeholder='Reason for Leaving' value={job1leave} onChange={this.handleKeyPress} />
                    </div>
                    <div className='position-field'>
                        <h3>Job 2</h3>
                        <p>Place of Employment</p>
                        <input className='name-input' type='text' name='job2Name' placeholder='Place of employment' value={job2Name} onChange={this.handleKeyPress} />
                        <p>Your position</p>
                        <input className='name-input' type='text' name='position2' placeholder='Your position' value={position2} onChange={this.handleKeyPress} />
                        <p>Date Started</p>
                        <input className='name-input' type='text' name='position2Start' placeholder='Date Started' value={position2Start} onChange={this.handleKeyPress} />
                        <p>Date Ended</p>
                        <input className='name-input' type='text' name='position2End' placeholder='Date Ended' value={position2End} onChange={this.handleKeyPress} />
                        <p>Wage</p>
                        <input className='name-input' type='text' name='wage2' placeholder='Wage' value={wage2} onChange={this.handleKeyPress} />
                        <p>Reason for Leaving</p>
                        <input className='name-input' type='text' name='job2Leave' placeholder='Reason for Leaving' value={job2leave} onChange={this.handleKeyPress} />
                    </div>
                    <div className='position-field'>
                        <h3>Job 3</h3>
                        <p>Place of Employment</p>
                        <input className='name-input' type='text' name='job3Name' placeholder='Place of employment' value={job3Name} onChange={this.handleKeyPress} />
                        <p>Your position</p>
                        <input className='name-input' type='text' name='position3' placeholder='Your position' value={position3} onChange={this.handleKeyPress} />
                        <p>Date Started</p>
                        <input className='name-input' type='text' name='position3Start' placeholder='Date Started' value={position3Start} onChange={this.handleKeyPress} />
                        <p>Date Ended</p>
                        <input className='name-input' type='text' name='position3End' placeholder='Date Ended' value={position3End} onChange={this.handleKeyPress} />
                        <p>Wage</p>
                        <input className='name-input' type='text' name='wage3' placeholder='Wage' value={wage3} onChange={this.handleKeyPress} />
                        <p>Reason for Leaving</p>
                        <input className='name-input' type='text' name='job3Leave' placeholder='Reason for Leaving' value={job3leave} onChange={this.handleKeyPress} />
                    </div>
                <div className='desired-employment'>
                    <h1 className='application-header'>Personal or Professional References</h1>
                    <hr></hr>
                </div>
                    <div className='position-field'>
                        <p>Reference Name</p>
                        <input className='name-input' type='text' name='reference1' placeholder='Reference Name' value={reference1} onChange={this.handleKeyPress} />
                        <p>Phone Number</p>
                        <input className='name-input' type='text' name='reference1Number' placeholder='Phone Number' value={reference1Number} onChange={this.handleKeyPress} />
                        <p>Reference Name</p>
                        <input className='name-input' type='text' name='reference2' placeholder='Reference Name' value={reference2} onChange={this.handleKeyPress} />
                        <p>Phone Number</p>
                        <input className='name-input' type='text' name='reference2Number' placeholder='Phone Number' value={reference2Number} onChange={this.handleKeyPress} />
                        <p>Reference Name</p>
                        <input className='name-input' type='text' name='reference1' placeholder='Reference Name' value={reference3} onChange={this.handleKeyPress} />
                        <p>Phone Number</p>
                        <input className='name-input' type='text' name='reference1Number' placeholder='Phone Number' value={reference3Number} onChange={this.handleKeyPress} />
                    </div>
                <div className='desired-employment'>
                    <h1 className='application-header'>Any other comments?</h1>
                    <hr></hr>
                </div>
                    <div>
                        <textarea className='comments-input' type='text' name='comments' placeholder='Please less than 900 characters' value={comments} onChange={this.handleKeyPress} />
                    </div>
                    <button type="submit" disabled={!isEnabled} className='application-btn'>Submit Application</button>
                </form>
            </div>
        )
    }
}

export default application;