import React from 'react';
import Prokopiak from '../../Utils/prokopiak.jpg';
import Lesson2 from '../../Utils/lesson2.jpg';
import Kelly from '../../Utils/kelly.jpg';
import Vista from '../../Utils/vista.jpeg';
import './Testimonials.css';

const testimonials = () => {
    return (
        <div className='testimonials'>
            <h1 className='testimonials-header'>Testimonials</h1>
            <div className='testimonial test-odd'>
                <div className='test-left'>
                    <div>
                        <div className="speech-bubble-ds">
                            <p>As a property manager of several homeowner associations that have pools, I have worked with a number of different pool companies during the last twenty years.  Doherty Pools have provided excellent service and they provide excellent supervision of their lifeguards which has created an atmosphere of friendliness and industriousness that I have not experienced with other companies.</p>
                            <div className="speech-bubble-ds-arrow"></div>
                        </div>
                        <div className='testimonial-name'>
                            <p className='test-info'>Rita Prokopiak</p>
                            <p className='test-info'>Prokopiak Management Company</p>
                        </div>
                    </div>
                    <div className='test-img-holder'>
                        <img alt='pro-img' className='pro-img' src={Prokopiak} />
                    </div>
                </div>
            </div>
            <div className='testimonial test-even'>
            <div className='test-left'>
                <div className='test-img-holder test-img2'>
                    <img alt='pro-img' className='pro-img2' src={Lesson2} />
                </div>
                <div>
                    <div className="speech-bubble-ds">
                        <p>I wanted to let you know that Connor did great at tryouts and we’ve received an email inviting him to join the competitive swim club he was trying out for! We wanted to send you a huge thank you for being there and seeing his talent in swim lessons and opening these doors! Maybe the stars have aligned and he’s found something he really enjoys and is good at! We are truly grateful! Please send Connor’s (swim lesson) teacher our heartfelt thanks and for all the work he did. We won’t ever forget.</p>
                        <div className="speech-bubble-ds-arrow"></div>
                    </div>
                    <div className='testimonial-name'>
                        <p className='test-info-even'>Parent</p>
                    </div>
                </div>
                </div>
            </div>
            <div className='testimonial test-odd'>
                <div className='test-left'>
                    <div>
                        <div className="speech-bubble-ds">
                            <p>I worked for another pool management company before I worked for Doherty Pool Management. The difference between the two is unmistakeable. To the other pool management company I was just a number,  to Doherty Pool Management I am family. The company's commitment to excellence, fairness, and honesty is apparent both in the way they treat their employees, and the guests at their facilities. As a family owned company the Doherty's make it a point to add a personal touch to everything they do. They get to know every one of their employees, just as they know their facilities and they always  strive to cultivate a culture in which it is only acceptable to do your best. They expect a lot from their employees, and do everything they can to encourage them to create a safe, clean, welcoming, and friendly environment at the facilities which they manage.</p>
                            <div className="speech-bubble-ds-arrow"></div>
                        </div>
                        <div className='testimonial-name'>
                            <p className='test-info'>Kelly McGuckin</p>
                            <p className='test-info'>Lifeguard</p>
                        </div>
                    </div>
                    <div className='test-img-holder'>
                        <img alt='pro-img' className='kelly-img' src={Kelly} />
                    </div>
                </div>
            </div>
            <div className='testimonial test-even'>
            <div className='test-left'>
                <div className='test-img-holder test-img2'>
                    <img alt='pro-img' className='pro-img3' src={Vista} />
                </div>
                <div>
                    <div className="speech-bubble-ds">
                        <p>Thanks Copperleaf staff! It has been a pleasure! You showed me the other side and we have higher expectations everywhere, but no one can compare to your staff!! They are awesome! Thank you</p>
                        <div className="speech-bubble-ds-arrow"></div>
                    </div>
                    <div className='testimonial-name'>
                        <p className='test-info-even'>Cindy Combs</p>
                        <p className='test-info-even'>Vista Management</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default testimonials;