import React from 'react';
import ServicePic from '../../Utils/service.jpg';
import Lesson from '../../Utils/lesson.jpg';
import './Services.css';

const services = () => {
    return (
        <div className='service'>
            <div className='services-left'>
                <h1 className='service-header'>Our Services</h1>
                <h2 className='maintenance'>Maintenance</h2>
                <p className='maintenance-info'>Our On-Site personnel are trained by Certified Pool Operators. Chemical readings are taken on the hour and adjustments made throughout the day so the pool is always sparkling and clean. Lifeguards follow a regimen of inspecting restrooms, emptying trash containers, cleaning tile, and keeping your facility one you can always be proud of. Our crew of experts reports in the spring to clean the pool and have it warm and ready for opening day. The On-Site staff handles daily maintenance chores ranging from backwashing the filters to vacuuming and everything else in between. In the fall, the pump room is skillfully winterized and all the pipes leading to and from the pool are blown out with compressed air. Chemical agents are added to the water and covers are installed so that the facility can be easily readied the following season.</p>
                <h2 className='maintenance'>Lifeguards</h2>
                <p className='maintenance-info'>Our Lifeguards are Red Cross trained and certified. Each guard must receive CPR/First Aid training each calendar year. Our staffs routinely practice emergency drills to be prepared for any event which could occur. On-Site supervisors evaluate on duty guards on a regular basis to ensure that every guard, every shift, is alert and practicing solid lifeguarding techniques. Guards are practiced in being in proper position, always scanning their area for concerns, and working with parents of weaker swimmers to maintain a safe environment.</p>
                <img alt='lesson-pic' className='lesson-pic' src={Lesson} />
            </div>
            <div className='services-right'>
                <img alt='service-pic' className='service-pic' src={ServicePic} />
                <h2 className='swim-title top-swim-title'>Swim Lessons</h2>
                <p className='swim-info'>We offer swim instruction in both the group format and private lessons. Group lessons operate on a maximum of five students to one teacher ratio. Young swimmers progress through learning skills starting with blowing bubbles, floating, gliding, arm stroking, and finally breathing and putting the whole stroke together. There is no greater peace of mind for a parent than knowing their child is safe in the water environment with the skills to enjoy swimming. Our Instructors guide youngsters through the process with enthusiasm and smiles.</p>
                <h2 className='swim-title'>Swim Team</h2>
                <p className='swim-info'>Our swim team coaches all have competitive swimming experience at the high school and collegiate level. We have the capability to offer spring clinics so that swimmers are ready for the season in June. Our coaches stress the fundamentals of stroke technique and concentrate on perfecting strokes and turns. Our programs work to incorporate parents to be involved with the team in socials and actually running the meets. We strive to make swim team an experience the entire community can enjoy. Our aim is to provide a fun and valuable experience which will be a springboard for young swimmers on their path to being strong, competent, competitive swimmers. Our coaches are mentored from a philosophy which has produced National Championships, multiple state championships and All American performers.</p> 
            </div>
        </div>
    )
}

export default services;