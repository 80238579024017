import React from 'react';
import { NavLink } from 'react-router-dom';
import StockLife from '../../Utils/life-stock.jpeg';
import './Apply.css';

const apply = () => {
    return (
        <div className='apply'>
            <div className='apply-top'>
                <div className='apply-head'>
                    <div className='header-holder'>
                        <h3 className='apply-header'>So You Want To Be A Lifeguard?</h3>
                    </div>
                    <div className='apply-head-img'>
                        <img alt='stock-life' className='stock-life' src={StockLife} />
                    </div>
                </div>
                <div className='requirements'>
                    <div className='ham-menu'>
                        <nav role="navigation">
                            <div id="menuToggle">
                                <input type="checkbox" />
                                <span></span>
                                <span></span>
                                <span></span>
                                <ul id="menu">
                                <a href="https://www.redcross.org/take-a-class/search?cgid=first-aid&zip=denver%2C+co&latitude=39.7392358&longitude=-104.990251&zipcode=&searchtype=class"><li>CPR/First-Aid/AED Courses</li></a>
                                <a href="https://www.redcross.org/take-a-class/search?cgid=online-safety-classes&zip=denver%2C+co&latitude=39.7392358&longitude=-104.990251&zipcode=&searchtype=class"><li>Get Certified Online</li></a>
                                <a href="https://www.redcross.org/local/colorado/take-a-class"><li>Aquatics(Lifeguard/Swim Lessons)</li></a>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <h3 className='requirements-header'>Requirements To Be A Lifeguard</h3>
                    <p className='req'>- Must be Red Cross Certified</p>
                    <p className='req-bullet'>- Lifeguard Training</p>
                    <p className='req-bullet'>- CPR</p>
                    <p className='req-bullet'>- First Aid</p>
                    <p className='req'>- Ability To Swim Well</p>
                    <p className='req'>- Must be 15 years or older</p>
                    <p className='req'>- Must have a knowledge of the aquatic environment and be ready to meet the needs of the swimmers, adults, and managers.</p>
                </div>
            </div>
            <div className='apply-top'>
                <div className='apply-head apply-head-lower'>
                    <div className='w4-holder'>
                        <a className='w4-btn' href='https://www.irs.gov/pub/irs-pdf/fw4.pdf'>Fill Out A W4</a>
                    </div>
                    <div className='apply-head-img'>
                        <NavLink to="/application" className='apply-online-btn'>
                            Apply Online!
                        </NavLink>
                    </div>
                </div>
                <div className='requirements'>
                    <div className='ham-menu'>
                        <nav role="navigation">
                            <div id="menuToggle">
                                <input type="checkbox" />
                                <span></span>
                                <span></span>
                                <span></span>
                                <ul id="menu">
                                <a href="https://www.redcross.org/take-a-class/search?cgid=lifeguarding&zip=denver%2C+co&latitude=39.7392358&longitude=-104.990251&zipcode=&searchtype=class"><li>Lifeguard, Water Safety, & Swim Courses</li></a>
                                <a href="https://www.redcross.org/take-a-class/search?cgid=swimming&zip=denver%2C+co&latitude=39.7392358&longitude=-104.990251&zipcode=&searchtype=class"><li>Water Safety Instructor Courses</li></a>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <h3 className='requirements-header req-header2'>Requirements To Be A Swim Instructor</h3>
                    <p className='req req2'>- Must have lifesaving skills</p>
                    <p className='req req2'>- Must be Red Cross Water Safety Certified</p>
                    <p className='req req2'>- Must be able to work with a timid student as well as a confident one</p>
                </div>
            </div>
        </div>
    )
}

export default apply;