import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../Home';
import About from '../About';
import Header from '../Header';
import Services from '../Services';
import Testimonials from '../Testimonials';
import Apply from '../Apply';
import Application from '../Application';
import Contact from '../Contact';
import Paypal from '../Paypal';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Switch>
          <Route exact path='/' render={() => {
              return <Home />
            }} />
          <Route exact path='/about' render={() => {
              return <About />
            }} />
          <Route exact path='/services' render={() => {
              return <Services />
            }} />
          <Route exact path='/testimonials' render={() => {
              return <Testimonials />
            }} />
          <Route exact path='/apply' render={() => {
              return <Apply />
            }} />
          <Route exact path='/application' render={() => {
              return <Application />
            }} />
          <Route exact path='/contact' render={() => {
              return <Contact />
            }} />
          <Route exact path='/payment' render={() => {
              return <Paypal />
            }} />
        </Switch>
      </div>
    );
  }
}

export default App;
