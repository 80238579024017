import React from 'react';
import Logo from '../../Utils/logo.jpg';
import { NavLink } from 'react-router-dom';
import './Header.css';

const header = () => {
    return (
        <div className='header'>
            <div className='header-bar'>
                <div className='left-btns'>
                    <NavLink to="/" className='home-btn header-btn'>
                        Home
                    </NavLink>
                    <NavLink to="/about" className='about-btn header-btn'>
                        About Us
                    </NavLink>
                    <NavLink to="/services" className='services-btn header-btn'>
                        Services
                    </NavLink>
                </div>
                <div className='img-holder'>
                    <img alt='logo' className='logo' src={Logo} />
                </div>
                <div className='right-btns'>
                    <NavLink to="/testimonials" className='testimonial-btn header-btn'>
                        Testimonials
                    </NavLink>
                    <NavLink to="/apply" className='apply-btn header-btn'>
                        Apply Now
                    </NavLink>
                    <NavLink to="/payment" className='contact-btn header-btn'>
                        Payment
                    </NavLink>
                    <NavLink to="/contact" className='contact-btn header-btn'>
                        Contact Us
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default header;