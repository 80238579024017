import React from 'react';
import Patio from '../../Utils/patio.jpg';
import Lifeguard from '../../Utils/lifeguard.jpg';
import './About.css';

const about = () => {
    return (
        <div className='about'>
            <div className='about-holder'>
                <h1 className='about-title'>About Us</h1>
                <p className='about-us'>Family owned and operated pool management and maintenance business incorporated in 1981, servicing the Southeast Denver area. Doherty Pool Management is one of the most respected and longstanding aquatic supervisory operations in Colorado. </p>
                <h1 className='services-header'>Our Services</h1>
                <p className='services'>We offer a full range of services including American Red Cross certified swim lessons, a full spectrum of pool maintenance options from Certified Pool Operators. We have the area’s most knowledgeable swim team coaches, on-site adult supervisors, trained professional and friendly lifeguards, and access to every necessary pool supply.</p>
                <h1 className='services-header'>Teamwork</h1>
                <p className='services services-bottom'>DPM adapts to meet your community needs. As a team, we will provide a clean and safe environment for your neighborhood.</p>
                <img alt='lifeguard' className='lifeguard' src={Lifeguard} />
            </div>
            <div className='patio-img-holder'>
                <img alt='patio' className='patio-img' src={Patio} />
                <h1 className='lifeguard-header'>Our Lifeguards</h1>
                <p className='lifeguard-points'>
                    - Competent (Each guard individually screened and interviewed.)
                </p>
                <p className='lifeguard-points'>
                    - Certified (American Red Cross)
                </p>
                <p className='lifeguard-points'>
                    - Dependable (Our guards on the average have over 2 years experience.)
                </p>
                <p className='lifeguard-points'>
                    - Organized (We hire great students, dedicated athletes, outstanding citizens.)
                </p>
                <p className='lifeguard-points'>
                    - Friendly, Enthusiastic (Prerequisite to be hired: must love kids, amazing game planner and band-aid changer.)
                </p>
            </div>
        </div>
    )
}

export default about;