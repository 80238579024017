import React from 'react';
import './Contact.css';

const contact = () => {
    return (
        <div className='contact'>
            <div className='upper-contact'>
                <h1 className='contact-header'>Contact</h1>
                <p className='contact-info'>Email: 7micdoherty7@gmail.com</p>
                <p className='contact-info'>Phone: 303.888.1834</p>
            </div>
            <div className='lower-contact'>
            </div>
        </div>
    )
}

export default contact;