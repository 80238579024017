import React from 'react';
import BackgoundImg from '../../Utils/main-background.jpg';
import './Home.css';

const home = () => {
    return (
        <div className='home'>
            <div className='homepage'>
                <div className='hometext'>
                    <h1 className='company-name home-header'>Doherty Pool Management</h1>
                    <p className='about-home'>Family owned and operated pool management and maintenance business
                        incorporated in 1981, servicing the Southeast Denver area. Doherty Pool
                        Management is one of the most respected and longstanding aquatic supervisory
                        operations in Colorado.</p>
                    <p className='teamwork'>DPM adapts to meet your community needs. As a team, we will provide a clean and safe environment for your neighborhood.</p>
                </div>
                <div className='home-img-holder'>
                    <img alt='home-img' className='home-img' src={BackgoundImg} />
                </div>
            </div>
        </div>
    )
}

export default home;